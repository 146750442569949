import { useQuery } from "@apollo/client"
import { SEO_QUERY } from "../graphql/queries/seo/seo"

export const useSeo = (id) => {
    const {data} = useQuery(SEO_QUERY,{
        variables:{
            id:id
        }
    })
    return {
        metaDescription:data?.about?.acfSeoData?.metaDescription,
        title:data?.about?.acfSeoData?.seoTitle
    }
}