import React from "react"
import Heading from "../Heading"
import "./about-content.scss"

function AboutContent({ textWithHeading }) {

  const subHeading = textWithHeading && textWithHeading.length >= 1 
    ? textWithHeading[0].subHeading 
    : null

  const heading = textWithHeading && textWithHeading.length >= 1
    ? textWithHeading[0].heading 
    : null

  const text = textWithHeading && textWithHeading.length >=1
    ? textWithHeading[0].text 
    : null

  return (
    <div className="about-content section">
      <Heading
        color="#0a7611"
        title={heading}
        supText={subHeading} 
      />
      <div class="container pd-row about-content__description"
        dangerouslySetInnerHTML={{ __html: text }}>
      </div>
    </div>
  )
}

export default AboutContent