/* eslint-disable max-len */
import React from "react"
import "./letter.scss"
import BackgroundImg from "../images/butcher-image-07.png"

const backgroundImg = {
  backgroundImage: `url(${BackgroundImg})`
}
const Letter = ({testimonial,isHome=false}) => {

  const title = testimonial ? testimonial[0].title : null;
  const quote = testimonial ? testimonial[0].quote : null;
  const author = testimonial ? testimonial[0].author : null;
  return (
    <div className="letter-component" style={backgroundImg}>
      <div className="letter-row">
        <div className="letter-wrapper">
          <div className="letter-title">
            <h4>testimonials</h4>
            <h2>{title}</h2>
          </div>
          <div className="letter-content">
            <p>&nbsp;</p>
            {!isHome ? <h4>“{quote}”</h4> :<h4 dangerouslySetInnerHTML={{__html:quote}}/>}
            <p style={{textAlign: "right"}}>– {author}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Letter
