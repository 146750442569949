import React from "react"
import "./about-heading.scss"

function AboutHeading(props) {
  
  const { textWithMedia, className, isJoin,setShow } = props
  const subHeading = textWithMedia && textWithMedia.length >= 1 
    ? textWithMedia[0].subHeading 
    : null

  const heading = textWithMedia && textWithMedia.length >= 1 
    ? textWithMedia[0].heading 
    : null
  const mediaUrl = textWithMedia && textWithMedia.length >= 1
    && textWithMedia[0].media.length >= 1
    ? textWithMedia[0].media[0].mediaItemUrl 
    : null
  
  const text = textWithMedia && textWithMedia.length >= 1
    ? textWithMedia[0].text 
    : null
    
  const typeMedia = textWithMedia && textWithMedia.length >=1
    && textWithMedia[0].media.length >= 1
    ? textWithMedia[0].media[0].mimeType
    : null
  
  return (
    <div className={`about-heading section ${className}`}>
      <div className="container pd-row">
        <div className="about-heading__left">
          <div className="about-heading__heading">
            <h4 style={{ color: "#000f02" }}>{subHeading}</h4>
            <h2 className="media-heading">
                <span>
                  {heading}
                </span>
            </h2>
          </div>
           <div className="about-heading__description" dangerouslySetInnerHTML={{ __html: text }} />

          {isJoin &&
          <button type="button" className="about-heading__btn" onClick={() => setShow(true)}>
              Join The Team
          </button>
          }
        </div>
        <div className="about-heading__right">
          {(typeMedia === "video/mp4") ? (
            <video src={mediaUrl} controls></video>
          ) : (
            <img src={mediaUrl} alt="" />
          )
          }
        </div>
      </div>
    </div>
  )
}

export default AboutHeading