import { gql } from "@apollo/client";

export const SEO_QUERY = gql`
query SeoQuery($id:ID!) {
    about(id: $id) {
      acfSeoData {
        metaDescription
        seoTitle
      }
    }
  }
`